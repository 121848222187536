import React from "react";

const Footer = () => {
  return (
    <div className="footer p-4 text-end ">
      <p className="mb-0">Future Lithics 2022</p>
    </div>
  );
};

export default Footer;
